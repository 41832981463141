body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

html.with-featherlight {
    overflow: auto;
}

#wrapper {
    position: relative;
    width: calc(100vh * (8906.6 / 650));
    height: 100%;
    max-height: 650px;
    overflow: hidden;

    #text,
    > img:not(.train) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > img.train {
        position: absolute;
        top: 636px;
        left: 90px;
        width: 339.61px;
        height: 108px;
        animation-name: train;
        animation-delay: 5s;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @media screen and (min-height: 650px) {
            top: 651px;
        }
    }

    > #text svg {
        letter-spacing: -1px;
        letter-spacing: -0.4px;
    }

    #carrousel {
        position: absolute;
        top: 392px;
        left: 4503px;
        width: 147px;
        height: 116px;

        .images {
            width: 100%;
            height: 100%;

            a {
                width: 100%;
                height: 100%;
                display: block;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url("../img/loupe.png") center no-repeat;
                    pointer-events: none;
                }

                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    #menu {
        position: fixed;
        top: -4px;
        right: 20px;
        background: #232a68;
        border: 4px solid #fff;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        width: 250px;
        color: #fff;
        font-family: "Work Sans", sans-serif;
        font-size: 14px;
        display: none;

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .expand {
            position: relative;
            padding-top: 10px;
            display: none;

            &:before {
                position: absolute;
                display: inline-block;
                content: "";
                border-left: 1px solid #fa9f31;
                height: 220px;
                top: -4px;
                left: 20px;
            }

            .title {
                font-family: "Work Sans", sans-serif;
                font-weight: 700;
                font-size: 18px;
                color: #fff;
                height: 40px;
                padding: 9px 0 10px 32px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0 0 15px 0;

                li {
                    position: relative;
                    list-style: none;
                    padding: 0 5px 0 32px;
                    margin: 15px 0 0 0;
                    line-height: 125%;

                    &:before {
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: 9px;
                        background: #fa9f31;
                        border-radius: 50%;
                        display: inline-block;
                        left: 16px;
                        top: 4px;
                    }
                }
            }
        }

        button {
            cursor: pointer;
            background: transparent;
            border: 0;
            color: #fff;

            &.open {
                font-family: "Work Sans", sans-serif;
                font-weight: 700;
                font-size: 18px;
                width: 100%;
                text-align: left;
                height: 40px;
                padding: 0 0 0 32px;
            }

            &.close {
                position: absolute;
                top: 10px;
                right: 8px;
                padding: 4px;
                line-height: 0;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        &.expanded {
            button.open {
                display: none;
            }

            .expand {
                display: block;
            }
        }
    }
}

.slick-list,
.slick-track {
    height: 100%;
}

.slick-dots {
    bottom: -21px;

    li {
        height: 10px;
        width: 10px;
        margin: 0 2px;

        button {
            height: 10px;
            width: 10px;
            padding: 0px;

            &:before {
                color: transparent;
                opacity: 1;
                border: 1px solid #fa9f31;
                border-radius: 50%;
                font-size: 10px;
                width: 8px;
                height: 8px;
            }
        }

        &.slick-active button:before {
            color: transparent;
            opacity: 1;
            background: #fa9f31;
        }
    }
}

#popin {
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
        letter-spacing: -1px;
        letter-spacing: -0.4px;
    }

    > img {
        height: 100%;
        width: auto;
    }
}

.featherlight {
    &:last-of-type {
        background: rgba(35, 42, 104, 0.66);
    }

    .featherlight-content {
        box-shadow: 0 0 43px 0px rgba(0, 0, 0, 0.83);
        padding: 20px 20px;
        border-bottom: 0;
    }

    .featherlight-close-icon {
        background: url("../img/close-bg.png") center no-repeat;
        width: 62px;
        height: 36px;
        color: #fff;
        font-size: 20px;
        padding-right: 12px;
        text-align: right;
        padding-bottom: 7px;
    }
}

@keyframes train {
    0% {
        top: 636px;
        left: 90px;
        width: 339.61px;
        height: 108px;
    }
    30% {
        top: 297px;
        left: 1390px;
        width: 339.61px;
        height: 108px;
    }
    100% {
        top: 297px;
        left: 1390px;
        width: 339.61px;
        height: 108px;
    }
}

@media screen and (min-height: 651px) {
    @keyframes train {
        0% {
            top: 651px;
            left: 90px;
            width: 339.61px;
            height: 108px;
        }
        30% {
            top: 316px;
            left: 1390px;
            width: 339.61px;
            height: 108px;
        }
        100% {
            top: 316px;
            left: 1390px;
            width: 339.61px;
            height: 108px;
        }
    }
}
